<div class="tab-container" style="min-height: 100%;  padding-top: 10px;">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" >
    <li [ngbNavItem]="1" *ngIf="!rawOnly">
      <a ngbNavLink>Summary</a>
      <ng-template ngbNavContent>
        <div class="image-container" *ngIf="data">
          <ng-container [ngSwitch]="getContentType()">
            <img  *ngSwitchCase="'image'" [src]="data.imageUrl || data.contentUrl " alt="Large Image" class="large-image">
            <video class="video" style="height: 450px; " *ngSwitchCase="'video'" controls>
              <source [src]="data.videoUrl || data.contentUrl" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <audio class="video" style="padding: 10px;" *ngSwitchCase="'audio'" controls>
              <source [src]="data.audioUrl || data.contentUrl" type="audio/mpeg">
              Your browser does not support the audio element.
            </audio>
            <!-- <p *ngSwitchCase="'text'" class="quote-text" style="margin-left: 20px;">{{ data.text }}</p> -->
            <div class="quote-container video" *ngIf="data.entryType === 'text'">
              <span class="quote-mark" *ngIf="data.content">“</span>
              <p  *ngSwitchCase="'text'" class="quote-text">{{ data.content }}</p>
            </div>
            
            
            
            <div *ngSwitchDefault>Unsupported content type</div>
          </ng-container>
          <hr style="width: 100%;">
          <div class="image-details">
            <div class="details-left">
              <div><b>Name:</b> {{ data.firstName }}</div>
              <div><b>Mobile Number:</b> {{ data.mobileNumber }}</div>
            </div>
            <div class="details-right">
              <div><b>Date: </b> <span class="date-display">{{ formatDate(data.createdAt) }}</span></div>
              <div><b>Status:</b> {{ data.moderationStatus }}</div>
            </div>
            
          </div>
          <ng-container *ngIf="showAdditionalDetails">
            <hr class="details-divider">
            <div class="image-details">
              <div class="details-left">
                <div *ngIf="data.voucher && data.voucher.trim()"><b>Voucher Type:</b> {{ data.voucher }}</div>
                <div *ngIf="data.name && data.name.trim()"><b>Voucher Code:</b> {{ data.name }}</div>
              </div>
              <div class="details-right">
                <div *ngIf="data.value && data.value" class="value"><b>Value:</b> {{ data.value }}</div>
              </div>
            </div>
          </ng-container>

        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Raw Data</a>
      <ng-template ngbNavContent>
        <div class="raw-data-container">
          <h4>Entry Details</h4>
          <pre>{{ data.entry | json }}</pre>
          <ng-container *ngIf="data.drawConfig">
            <h4>Draw filters</h4>
            <pre>{{ data.drawConfig | json }}</pre>
          </ng-container>
        </div>
      </ng-template>
    </li>
    
  </ul>
  <div class="icon-container">
    <i class="material-icons download-icon" (click)="downloadContent()">file_download</i>
    <i class="material-icons close-icon" (click)="closeView()">close</i>
  </div>
  <div [ngbNavOutlet]="nav"></div>
</div>
