import { AfterViewInit, Component, EventEmitter, Output, ViewChild,SimpleChanges ,ChangeDetectorRef, ElementRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription} from 'rxjs';
import { ProjectService } from '../../shared/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ColorService } from '../../shared/services/theme.service'
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import {LargeImageViewComponent} from '../../large-image-view/large-image-view.component'
import { RouterModule } from '@angular/router';
import { FormsModule, FormControl } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { DatePipe } from '@angular/common';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmationDialogComponent} from '../../confirmation-dialog/confirmation-dialog.component';
import {ConfirmationMessageComponent} from '../../../confirmation-message/confirmation-message.component';
import { Router } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { ReactiveFormsModule } from '@angular/forms'; 
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CodeLargeImageComponent } from 'src/code-large-image/code-large-image.component';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { StandardMessageDrawToolComponent } from 'src/app/standard-message-draw-tool/standard-message-draw-tool.component';
import { SharedModule } from "../../shared/shared.module";


export interface Entry {
  id: string;
  consumer: {
    firstName: string;
    createdAt: string;
    lastName: string;  
  };
  moderationStatus: string;
  entryType: string;
  entryStatus: string;
}

@Component({
  selector: 'app-paper-trails',
  standalone: true,
  imports: [FormsModule  ,MatProgressSpinnerModule, ReactiveFormsModule, AvatarModule, MatButtonModule, MatMenuModule, MatToolbarModule, MatInputModule, MatSort, MatSortModule, DatePipe, MatMenuModule, MatSelectModule, MatFormFieldModule, RouterModule, CommonModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatIconModule, FormsModule, SharedModule],
  templateUrl: './paper-trails.component.html',
  styleUrl: './paper-trails.component.scss'
})
export class PaperTrailsComponent implements AfterViewInit{
  defaultImageUrl: string = 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png';
  projectDetails: any;
  showNoResult = false; 
  private inputSubscription: Subscription;
  @Input() token;
  @ViewChild('searchInput') input: ElementRef;
  exportListItem$: Observable<any[]>;
  @Output() exportSummaryEvent = new EventEmitter<void>();
  @Output() exportAllEvent = new EventEmitter<void>();
  currentPage = 1;
  selectedIds = new Set<string>();
  pageSize = 10;
  totalPages = 0;
  displayStartIndex: number = 1;
  displayEndIndex: number = 10;
  totalRecords: number = 0; 
  isDropdownOpen: boolean = false;
  entryTypeOptions: string[] = [];
  statusOptions: string[] = [];
  moderationStatusOptions: string[] = [];
  showCheckbox = true; 
  searchControl = new FormControl();
  selectedModerationStatus: string | null = null;
  selectedEnteryValue: string | null = null;
  selectedStatusValue: string | null = null;
  private unsubscribe$: Subject<void> = new Subject();
  selectedStatus: string;
  filteredEntries: Entry[] = [];
  searchText: string = '';
  checkbox: string = '';
  colorScheme: string;
  userId: string = '';
  entries: any[] = [];
  private _id;
  isDeselectAllChecked = false;
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['select', 'date', 'mobileNumber','Region','image', 'moderationStatus' ,'prize', 'value' ];
  showSearchContainer: boolean = true;
  valueSelected: boolean = false;
  entryPlaceholderText: string = 'Select entry'
  placeholderText: string = 'Select Status: ';
  @ViewChild(MatSort) sort: MatSort;
  isLoading = false; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  entry: any;
  searchSubject$ = new Subject<string>(); 
  destroy$: Subject<boolean> = new Subject()

private _paging = {
  skip:0,
  take:10,
  orderBy:'createdAt',
  orderDirection:'desc',
  moderationStatus: '' ,
  Type: '',
  mobileNumber: '',
  Status: ''
};


  constructor(private http: HttpClient, 
             private projectService: ProjectService, 
             private route: ActivatedRoute,
             private colorService: ColorService,
             private dialog: MatDialog,
             private routerModule: RouterModule,
             private router: Router,
             private datePipe: DatePipe,
             private cdr: ChangeDetectorRef,
             private snackBar: MatSnackBar,
             private toastrService: ToastrService,
            ) {this.exportListItem$ = this.projectService.exportList$;
             }

  ngOnInit(): void {   
    this.route.params.subscribe(params => {
      const id = params['id']; 
      this.userId = id; 
      this.getProjectDetails(id);
      if (this.userId) {
        this.getProjectEntries(this.userId);
      }
    });
    this.moderationStatuses();
    this.entryTpes();
    this.statuses();
  }
  
  openPopup(entry: any, rawOnly: boolean = false) {
    const dialogRef = this.dialog.open(LargeImageViewComponent, {
        data: { entry: entry, rawOnly: rawOnly },
        width: '450px',
        height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('Dialog closed with:', result);
    });
} 

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.sort.active = 'date';  
    this.sort.direction = 'desc';
    
    this.searchSubject$
      .pipe(
        debounceTime(1000),  // Wait for 1 second after the user stops typing
        distinctUntilChanged(),  // Ignore if the search query hasn't changed
        takeUntil(this.destroy$)  // Unsubscribe when the component is destroyed
      )
      .subscribe((query) => {
        this.searchText = query;
        this._paging.mobileNumber = query;
        this.getProjectEntries(this.userId);
        //this.projectListService.load(this._paging);  // Trigger search after debounce
      });
  }
  

  applyFilters() {
    const searchValue = this.input.nativeElement.value.trim();
    console.log('Search Value:', searchValue);
    this.searchSubject$.next(searchValue);
  
    let moderationStatusChanged = false;
    const newModerationStatus = this.selectedModerationStatus === 'all' ? '' : this.selectedModerationStatus || '';
    console.log('Dropdown selected value:', this.selectedModerationStatus);
    console.log('Calculated paging entryType:', newModerationStatus);
    
    if (this._paging.moderationStatus !== newModerationStatus) {
      this._paging.moderationStatus = newModerationStatus;
      moderationStatusChanged = true;
    }
  
    if (moderationStatusChanged) {
      this._paging.skip = 0; 
      console.log('Updated paging object111:', this._paging);
      this.getProjectEntries(this.userId);
      this.cdr.detectChanges(); 
    }
  }

  entryFilter() {
    const entryValue = this.input.nativeElement.value.trim();
    console.log('Search Value:', entryValue);
    this.searchSubject$.next(entryValue);
    
    let enteryValueChanged = false;
    const newEntryValue = this.selectedEnteryValue === 'all' ? '' : this.selectedEnteryValue || '';
  
    console.log('Dropdown selected value:', this.selectedEnteryValue);
    console.log('Calculated paging entryType:', newEntryValue);
  
    if (this._paging.Type !== newEntryValue) {
      console.log('Changing _paging.entryType from', this._paging.Type, 'to', newEntryValue);
      this._paging.Type = newEntryValue;
      enteryValueChanged = true;
    }
  
    if (enteryValueChanged) {
      this._paging.skip = 0; // Reset pagination on filter change
      console.log('Updated paging object:', this._paging);
      this.getProjectEntries(this.userId); // Call API with updated paging object
      this.cdr.detectChanges();
    } else {
      console.log('No change in filter detected, skipping API call.');
    }
  }
  
  

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }
  
  refreshComponent() {
    this.cdr.detectChanges();
    
  }

  getProjectEntries(userId: string): void {
    this.isLoading = true; 
    this.projectService.getProjectEntries(userId, this._paging, this.token).subscribe({
      next: (data) => {
        if (!data || !data.items) {
          console.error('Unexpected API response:', data);
          this.isLoading = false;
          return;
        }
        console.log('Entries before filtering:', data.items);
        
        this.totalRecords = (data.pageCount - 1) * data.pageSize + data.items.length;
        this.totalPages = data.pageCount;

        this.entries = data.items; // Use API-filtered results directly
        this.dataSource.data = [...this.entries];
        
        // // Client-side filtering
        let filteredItems = data.items;


  


      if (this.entries.length === 0) {
        this.dataSource.data = []; // Clear the dataSource
        this.showNoResult = true;   // Show the "no result" message
      } else {
        this.dataSource.data = this.entries;
        this.showNoResult = false;  // Hide the "no result" message
      }
        
        // console.log('Entries:', this.entries); 
        this.entries.forEach(entry => {
          // console.log('text',entry.payload.content);
          // console.log('contentUrl', entry.payload.contentUrl);
          // console.log('ImageUrl', entry.payload.imageUrl)
        });
        this.updateDisplayedEntries();
        this.isLoading = false; 
      },
      error: (error) => {
        console.error('Error fetching project entries:', error);
        this.isLoading = false; 
      }
    });
  }

  statusFilter() {
    const statusValue = this.input.nativeElement.value.trim();
    console.log('Search Value:', statusValue);
    this.searchSubject$.next(statusValue);

    let statusChanged = false;
    const newStatusValue = this.selectedStatusValue === 'all' ? '' : this.selectedStatusValue || '';
    console.log('Dropdown selected value:', this.selectedStatusValue);
    console.log('Calculated paging entryType:', newStatusValue);

    if (this._paging.Status !== newStatusValue) {
      console.log('Changing _paging.entryType from', this._paging.Status, 'to', newStatusValue);
      this._paging.Status = newStatusValue;
      statusChanged = true;
    }

    if (statusChanged) {
      this._paging.skip = 0;
      console.log('Updated paging object333:', this._paging);
      this.getProjectEntries(this.userId);
      this.cdr.detectChanges();
    } else{
      console.log('No change in filter detected, skipping API call.');

    }
  }



  toggleSelection(row: any) {
  if (this.selection.isSelected(row)) {
    this.selectedIds.delete(row.id);
  } else {
    this.selectedIds.add(row.id);
  }
  this.selection.toggle(row);
  this.updateDeselectAllCheckbox();

}


getProjectDetails(id: string): void {
  this.colorService.getProjectById(id, this.token).subscribe(
    (projectDetails) => {
      console.log('Project details:', projectDetails);
      this.colorScheme = projectDetails.colorScheme;
      this.projectDetails = projectDetails;
      if (projectDetails.configuration) {
        console.log('Project Configuration:', projectDetails.configuration);
      }
    },
    (error) => {
      console.error('Error fetching project details:', error);
      console.log("Error", error);
    }
  );
}

  


  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedIds.clear();
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => {
      if (row.moderationStatus !== 'blacklisted' ) {
        this.selectedIds.add(row.id);
        this.selection.select(row);
      }

      });
    }
    this.updateDeselectAllCheckbox();
  }
  
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => row.moderationStatus !== 'blacklisted').length;
    return numSelected === numRows;
  }
  


  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    const startIndex = event.pageIndex * event.pageSize;
    this._paging.skip = startIndex;
    this._paging.take = event.pageSize;
    this.getProjectEntries(this.userId);
  }
  
  
  showLargeImage(
    imageUrl: string, 
    firstName: string, 
    createdAt: string, 
    mobileNumber: number, 
    moderationStatus: string, 
    voucher: number,
    name: string, 
    value: number,
    entryId: string,  
    entryStatus: string,
    audioUrl: string,
    videoUrl: string,
    content: string,
    entryType:string,
    contentUrl: string
): void {
    console.log('Entry ID received:', entryId);  
    const entry = this.entries.find(item => item.id === entryId);

    if (!entry) {
        console.error(`Entry not found for id: ${entryId}`);
        return;
    }

    console.log('Entry found:', entry);

    const dialogRef = this.dialog.open(LargeImageViewComponent, {
        data: {
          entryType:entryType,
          content: content,
            videoUrl: videoUrl,
            audioUrl:audioUrl,
            imageUrl: imageUrl,
            firstName: firstName,
            createdAt: createdAt,
            mobileNumber: mobileNumber,
            voucher: voucher,
            jsonResponse: entry,
            name: name,
            value: value,
            entryStatus: entryStatus,
            moderationStatus: moderationStatus,
            consumerId: entryId,
            entry: entry,
            contentUrl: contentUrl
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
}


  CodeImage(Code: string,Status: string,BankName: string,firstName:string, createdAt: string, mobileNumber: string, moderationStatus: string, entryId: string,entryStatus: string): void {
    console.log('Code Entry ID received:', entryId);  
    const entry = this.entries.find(item => item.id === entryId);

    if (!entry) {
        console.error(`Code Entry not found for id: ${entryId}`);
        return;
    }

    console.log('Code Entry found:', entry);
    const dialogRef = this.dialog.open(CodeLargeImageComponent, {
      data: { 
        entryStatus:entryStatus,
        firstName: firstName,
        createdAt: createdAt,
        mobileNumber:mobileNumber,
        moderationStatus,
        BankName:BankName,
        Status:Status,
        Code:Code,
        entry: entry 
       }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  

  toggleRowSelection(event: MouseEvent, entry: any): void {
    // Check if the clicked element is the checkbox
    const target = event.target as HTMLElement;
    const isCheckbox = target.tagName === 'MAT-CHECKBOX';
    // If not a checkbox, toggle selection
    if (!isCheckbox) {
      this.selection.toggle(entry);
    }
  }


  
  selectAll(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.dataSource.data.forEach(row => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  
  async confirmUpdate() {
    console.log("in ConfirmUpdate", this.selection.selected);
  
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        selectedRecords: this.selection.selected,
        updatedStatus: this.selectedStatus
      }
    });
  
    console.log("in ConfirmUpdate down", this.selection.selected);
  
    try {
      const result = await dialogRef.afterClosed().toPromise();
      if (result === 'confirm') {
        this.isLoading = true;
        await this.updateModerationStatus(this.selection.selected, this.selectedStatus);
        this.UpdateConfirmationMessage(this.selection.selected);
        await this.refreshComponent();
      }
    } catch (error) {
      this.showErrorMessage('Error occurred: ' + error);
    } finally {
      this.isLoading = false;
    }
  }
  
  
  UpdateConfirmationMessage(selectedRecords: any[]) {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '400px',
      height: '200px',
      data: {
        selectedRecords: selectedRecords,
        updatedStatus: this.selectedStatus
      }
    });
  
    dialogRef.afterClosed().subscribe(() => {
      this.isLoading = false; 
    });
    this.selection.clear();
  }

  confirmation() {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '400px',
      height: '200px',
      data: {
        selectedRecords: this.selection.selected,
        updatedStatus: this.selectedStatus
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        
      }
    });
  }
  
  
  
  cancelUpdate() {
    this.selection.clear();
  }
  
  updateModerationStatus(records: any[], status: string): Promise<void> {
    const body = {
      status: status,
      entries: records.map(record => record.id)  
    };
  
    return new Promise((resolve, reject) => {
      this.http.patch(`${environment.endpoints.api}projects/${this.userId}/entries/moderate?token=${this.token}`, body)
        .subscribe({
          next: (response) => {
            // console.log('Status update successful:', response);
            this.getProjectEntries(this.userId);
            resolve(); 
          },
          error: (error) => {
            // console.error('Failed to update statuses:', error);
            reject(error); 
          }
        });
    });
  }
  
  
  onStatusSelect(event: any): void {
    this.valueSelected = true;
  }
  // isValidSelectedModerationStatus(): boolean {
  //   return this.moderationStatusOptions.includes(this.selectedModerationStatus);
  // }

  formatDate(dateString: string | null): string {
    if (!dateString) {
      return 'No Date Provided'; 
    }
    const formattedDate = this.datePipe.transform(dateString, 'dd MMM, yyyy hh:mm a');
    return formattedDate || 'Invalid Date'; 
  }

  exportSummary() {
    console.log('Exporting Summary');
    this.exportSummaryEvent.emit();
  }


  exportAll() {
    console.log('Exporting All Data');
    this.exportAllEvent.emit();
  }
//   exportSelectedEntries() {
//     const selectedEntries = this.selection.selected; // This fetches all selected items
//     this.downloadExport();
// }
exportAllEntries() {
  this.downloadExport(this.entries); 
}
// let _config = {
//   "type": "draws",
//   "configuration": {
//     "draw": this.drawId,
//     "entries": data.items.length,
//     "config": this.drawConfig
//   },
//   "name": this.drawName,
//   "description": "Export of draw "
// };

download(){
  
  let _config = {
    "type": "entries",
    "configuration":  this.projectDetails.configuration,
    "name": "All Entries",
    "description": 'Entries exported'
  }
  // console.log(this.userId,'En-tries ID')
  this.showExportMessage();
  this.projectService.exportEntries(_config,this.userId, this.token);
  // this.toastrService.info('All entries queued for export', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
}

downloadExport(item){
  this.projectService.downloadExport(item, this.token)
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['selectedModerationStatus'] && !changes['selectedModerationStatus'].firstChange) {
    this.updatePlaceholder();
  }
    if (changes['selectedEnteryValue'] && !changes['selectedEnteryValue'].firstChange) {
    this.updatePlaceholder();
  }
  if (changes['selection'] && !changes['selection'].currentValue.hasValue()) {
    this.isDeselectAllChecked = true;
  }
}




updatePlaceholder(): void {
  this.placeholderText = this.selectedModerationStatus 
    ? `Select Status: ${this.selectedModerationStatus}` 
    : 'Select Status';

  this.entryPlaceholderText = this.selectedEnteryValue 
    ? `Entry Type: ${this.selectedEnteryValue}` 
    : 'Entry Type';

  this.cdr.detectChanges(); 
}

fetchStatuses() {
  this.projectService.fetchModerationStatuses(this.token);
}

moderationStatuses(){
  this.projectService.moderationStatuses$.pipe(
    takeUntil(this.unsubscribe$)
  ).subscribe(
    (statuses: string[]) => {
      this.moderationStatusOptions = statuses;
      console.log('Received statuses:', statuses);
    },
    error => console.error('Error receiving statuses', error)
  );

  this.fetchStatuses();
}


entryTpes() {
  console.log('inside entryTpes');
  this.isLoading = true;
  this.projectService.getEntryTypes(this.token).subscribe({
    next: (data: any) => {
      console.log(data, 'ENTRY TYPES');
      this.entryTypeOptions = data;
      this.isLoading = false;
      this.cdr.detectChanges();
    },
    error: (error) => {
      console.error('Error fetching entry types:', error);
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  });
}

statuses(){
  console.log('inside statuses');
  this.isLoading = true;
  this.projectService.getStatuses(this.token).subscribe({
    next: (data: any) => {
      console.log(data, 'Statuses');
      this.statusOptions = data;
      this.isLoading = false;
      this.cdr.detectChanges();
    },
    error: (error) => {
      console.error('Error fetching statuses', error);
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  });
}

updateDisplayedEntries() {
  const startIndex = (this.currentPage - 1) * this.pageSize;
  const endIndex = Math.min(startIndex + this.pageSize, this.totalRecords);
  this.displayStartIndex = startIndex + 1;
  this.displayEndIndex = endIndex;
  this.dataSource.data = this.entries.slice(startIndex, endIndex);
}

viewEntry(entry: any): void {
  this.projectService.setEntryData(entry);
  this.router.navigate(['/entry-data']);
}

deselectAll(event: Event): void {
  const checkbox = event.target as HTMLInputElement;
  if (!checkbox.checked) {
    this.selection.clear();
    this.selectedIds.clear();
  }else{
    
  }
  this.isDeselectAllChecked = true;  
  this.cancelUpdate();
}
displayEntryType(value: string): string {
  if (value && value !== 'all') {
    return `Entry type: ${value}`;
  } else {
    return 'Entry Type: All';
  }
}

updateDeselectAllCheckbox() {
  this.isDeselectAllChecked = this.selection.hasValue();
}

showExportMessage(): void {
  this.dialog.open(StandardMessageDrawToolComponent, {
    data: { message: 'Export initiated!' }
  });
}

showErrorMessage(message: string) {
  this.snackBar.open(message, 'Close', {
    duration: 3000,
    verticalPosition: 'top', 
    horizontalPosition: 'end', 
  });
}

isImage(url: string): boolean {
  return /\.(jpeg|jpg|gif|png)$/.test(url);
}

isVideo(url: string): boolean {
  return /\.(mp4|mov)$/.test(url);
}

isAudio(url: string): boolean {
  return /\.(wav|mp3|ogg)$/.test(url);
}
displayTextEntry(text: string, id: string) {
}

showVideoPopup(entry: any) {
  this.dialog.open(LargeImageViewComponent, {
    data: { videoUrl: entry.payload.videoUrl }
  });
}

showAudioPopup(entry: any) {
  this.dialog.open(LargeImageViewComponent, {
    data: { audioUrl: entry.payload.audioUrl }
  });
}

showTextPopup(entry: any) {
  this.dialog.open(LargeImageViewComponent, {
    data: { content: entry.payload.content }
  });
}


}