
@if(!(expired$ | async)){
  <div class="card" [ngClass]="{public: _isPublic}" style="margin: 0 -30px 30px -30px">
    <div class="card-body" style="padding:10px;">
      @defer {
        @if(_nav){
          <div class="row">
            @if(!_isPublic){
              <!-- <app-breadcrumb [titleInput]="_nav.name + ' Dashboard'" [childInput]="''" [back]="!_isPublic ? true : false" [backLink]="'project-list'"></app-breadcrumb> -->
             <div class="d-block">
              <!-- <div style="display: inline-block;padding-left:30px; width:100px;vertical-align: middle;">
                <img class="brand-logo d-flex" [src]="(project$ | async)?.brand.imageUri" /> 
              </div> -->
              <div style="display: inline-block;padding-left:30px; width:100px;vertical-align: middle;">
                <ng-container *ngIf="(project$ | async)?.brand.imageUri; else defaultAvatar">
                  <img class="brand-logo d-flex" [src]="(project$ | async)?.brand.imageUri" />
                </ng-container>
                <ng-template   #defaultAvatar>
                  <ngx-avatars [name]="getInitials(_nav.name)"></ngx-avatars>


                </ng-template>
              </div>
              
              <div style="display: inline-block;width: calc(100% - 200px); vertical-align: middle;">
                <app-breadcrumb class="d-flex" [padding]="true" [titleInput]="_nav.name + ' Dashboard'" [childInput]="''" [back]="false" [backLink]="'project-list'"></app-breadcrumb>
                <div style="padding-left:12px;color:#aeaeae">{{(project$ | async)?.configuration?.dashboard?.reportsStartAt | date: 'dd MMMM yyyy'}} - {{(project$ | async).configuration?.dashboard?.reportsEndAt | date: 'dd MMMM yyyy'}}</div>
              </div>
               
             </div>
              
  
            }@else {


              <div class="d-flex" style="gap: 25px; margin: 10px;">
                <div *ngIf="(project$ | async)?.brand.imageUri; else defaultAvatar" style="display: inline-block; width:80px;vertical-align: middle;">
                  <img class="brand-logo d-flex" [src]="(project$ | async)?.brand.imageUri " />
                </div>
                <ng-template #defaultAvatar>
                  <ngx-avatars [name]="getInitials(_nav.name)"></ngx-avatars>
               </ng-template>
                <div style="display: inline-block;width: calc(100% - 110px); vertical-align: middle;">
                  <h3 style="margin:0">{{_nav.name + ' Dashboard'}}</h3>
               <div style="color:#aeaeae">{{(project$ | async)?.configuration?.dashboard?.reportsStartAt | date: 'dd MMMM yyyy'}} - {{(project$ | async).configuration?.dashboard?.reportsEndAt | date: 'dd MMMM yyyy'}}</div>
                </div>
                 
               </div>
              
            }                           
          </div>     
          }
      }
    </div>
  </div>
  <div>

    
<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onNavChange($event)" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Dashboard</a>
    @if(activeTab === 1){
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-xl-12 xl-100">
            <div class="row">
              <div class="col-sm-12">
                @defer {
                @if(!(recruitmentSummaryLoading$ | async) && !(subscriptionStatusSummaryLoading$ | async)){
                <app-summary-chart [data]="(recruitmentSummary$ | async)"
                  [data2]="(subscriptionStatusSummary$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-summary-chart>
                }
                }
              </div>
              <div class="col-sm-12">
                <h5 class="section-title" style="padding-left: 15px;">Entries over time</h5>
                @defer {
                @if(!(entriesByDayLoading$ | async)){
                <app-daily-chart [loading]="(entriesByDayLoading$ | async)"
                  [data]="(entriesByDay$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-daily-chart>
                }
                @else{
                <div class="container-fluid" >
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Daily</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
                @defer {
                @if(!(entriesByHourLoading$ | async)){
                <app-hourly-chart [loading]="(entriesByHourLoading$ | async)"
                  [data]="(entriesByHour$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-hourly-chart>
                }
                @else {
                <div class="container-fluid">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Hour of day</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
              </div>
              <div class="col-sm-12">
                <h5 class="section-title" style="padding-left: 15px;">Entries status</h5>
                @defer {
                @if(!(statusByDayLoading$ | async)){
                <app-entry-status-daily-chart [data]="(statusByDay$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-entry-status-daily-chart>
                }
                @else {
                <div class="container-fluid">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Hour of day</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
                @defer {
                @if(!(statusSummaryLoading$ | async)){
                <app-entry-status-chart [data]="(statusSummary$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-entry-status-chart>
                }
                @else {
                <div class="container-fluid">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Hour of day</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
              </div>
              <div class="col-sm-12">
                <h5 class="section-title" style="padding-left: 15px;">Prizes Awarded</h5>
                @defer{
                @if(!(rewardsByDayLoading$ | async)){
                <app-prize-types-daily-chart  [data]="(rewardsByDay$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-prize-types-daily-chart>
                }
                @else{
                <div class="container-fluid">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Hour of day</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
                @defer{
                @if(!(rewardsSummaryLoading$ | async)){
                <app-prize-types-chart [data]="(rewardsSummary$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-prize-types-chart>
                }
                @else {
                <div class="container-fluid">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Hour of day</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
              </div>
              <div class="col-sm-12">
                <h5 class="section-title" style="padding-left: 15px;">Consumer Data</h5>
                @defer {
                @if(!(subscriptionStatusByDayLoading$ | async)){
                <app-consumer-optin-chart [data]="(subscriptionStatusByDay$ | async)" [project]="(project$ | async)?.configuration?.dashboard"></app-consumer-optin-chart>
                }
                @else {
                <div class="container-fluid">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-header b-l-primary" style="padding:12px">
                        <h6>Hour of day</h6>
                      </div>
                      <app-chart-load class="margin-auto"></app-chart-load>
                    </div>
                  </div>
                </div>
                }
                }
                @if(!(dataEnrichmentSummaryLoading$ | async) && !(recruitmentSummaryLoading$ | async) && !(subscriptionStatusSummaryLoading$ | async)){
                <app-consumer-targets [data2]="(recruitmentSummary$ | async)" [data3]="(subscriptionStatusSummary$ | async)"
                [project]="(project$ | async)?.configuration?.dashboard" [data]="(dataEnrichmentSummary$ | async)">
                </app-consumer-targets>
                }
                @defer {
                @if(!(dataEnrichmentSummaryLoading$ | async) && !(recruitmentSummaryLoading$ | async) && !(subscriptionStatusSummaryLoading$ | async)){
                <app-enrichment-targets class="my-2" [data2]="(recruitmentSummary$ | async)" [data3]="(subscriptionStatusSummary$ | async)"
                [project]="(project$ | async)?.configuration?.dashboard" [data]="(dataEnrichmentSummary$ | async)" ></app-enrichment-targets>
                }
                }
              </div>
            </div>
          </div>
        </div>
       </ng-template>
    }                  
  </li>
  
<li [ngbNavItem]="2">
  <a ngbNavLink>Paper Trail</a>
  @if(activeTab === 2){    
    <ng-template ngbNavContent>
        <app-paper-trails [token]="_token"></app-paper-trails>
    
    </ng-template> 
  }
</li>
<!-- @if((project$ | async)?.configuration?.draws?.enabled){ -->

@defer{
    <li [ngbNavItem]="3">
      <a ngbNavLink>Draw Tool</a>
      @if(activeTab === 3){    
        <ng-template ngbNavContent>
         
            <app-draw-tool [token]="_token" [project]="(project$ | async)"></app-draw-tool>
        
        </ng-template> 
      }
    </li>


  }
<!-- } -->

<!-- SHOW AND HIDING OF TABS -->
<!-- @if((project$ | async)?.configuration?.draws?.enabled){ -->
<li [ngbNavItem]="4">
  <a ngbNavLink>Exports</a>
  @if(activeTab === 4){  
  <ng-template ngbNavContent>
    <app-exports [token]="_token"></app-exports>
  </ng-template>
}

</li>
<!-- } -->


<!-- <li [ngbNavItem]="5">
  <a ngbNavLink>Consumer</a>
  @if(activeTab === 5){    
    <ng-template ngbNavContent>
     
        <app-consumer></app-consumer>
    
    </ng-template> 
  }
</li> -->
<!-- <li [ngbNavItem]="6">
  <a ngbNavLink>Entry data</a>
  @if(activeTab === 6){    
    <ng-template ngbNavContent>
     
        <app-entry-data></app-entry-data>
    
    </ng-template> 
  }
</li> -->
 
</ul> 
<div [ngbNavOutlet]="nav" class="pt-2" style="border:1px solid rgb(222, 226, 230); border-top:0; background-color:#fff"></div>  

  </div>
}@else {
  <div class="pt-5">
    <h2 class="text-center font-bold"><strong>SORRY!</strong></h2>
    <h4 class="text-center">YOUR TOKEN HAS EITHER EXPIRED OR IS NO LONGER VALID.</h4>
  </div>
  
}







