import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe,CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbNavChangeEvent, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-large-image-view',
  standalone: true,
  imports: [CommonModule,DatePipe, MatTabsModule,NgbNavModule],
  templateUrl: './large-image-view.component.html',
  styleUrl: './large-image-view.component.scss'
})
export class LargeImageViewComponent {
  activeTab = 1;
  rawOnly: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<LargeImageViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
    entryType:string,
    videoUrl:string,
    audioUrl:string,
    content: string,
    jsonResponse: any
    imageUrl: string , 
    firstName: string, 
    id: any,
    createdAt?: string , 
    mobileNumber: number, 
    voucher: string,
    name: string,
    value: number,
    entry: any,
    entryStatus: string,
    drawConfig: any,
    rawOnly: any
    moderationStatus: string,
    contentUrl: string
  }, private datePipe: DatePipe)
   {
    this.rawOnly = !!data.rawOnly;
    if (this.rawOnly) {
      this.activeTab = 2; // Set to Raw Data tab if rawOnly is true
    }
    console.log('Data received in dialog:', this.data);
  }
   

  formatDate(dateString: string | null | undefined): string {
    if (!dateString) {
      return 'No Date Provided';
    }
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric', 
      month: 'long', 
      year: 'numeric'
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  
  onNavChange(event: NgbNavChangeEvent) {
    console.log('Active Tab Changed to:', event.nextId);
  
    if (event.nextId === 2) { 
      this.downloadRawData();
    } else if (event.nextId === 1) { 
      this.downloadImage();
    }
  }
  
  contentDownload() {
    
    switch (this.getContentType()) {
      case 'image':
        this.downloadFile(this.data.imageUrl, 'downloaded-image.jpg');
        break;
      case 'video':
        this.downloadFile(this.data.videoUrl, 'downloaded-video.mp4');
        break;
      case 'audio':
        this.downloadFile(this.data.audioUrl, 'downloaded-audio.mp3');
        break;
      case 'text':
        this.downloadText(this.data.content, 'downloaded-text.txt');
        break;
      default:
        console.log('Attempting to download raw data...');
        // this.downloadRawData(); // Handle raw data download if no other content type matches
        break;
    }
  }
  
  
  downloadFile(url: string, filename: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  
  downloadText(content: string, filename: string) {
    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  downloadImage() {
    const a = document.createElement('a');
    a.href = this.data.imageUrl && this.data.contentUrl;
    a.download = 'downloaded-image';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloadRawData() {
    const combinedData = {
      entry: this.data.entry,
      drawConfig: this.data.drawConfig
  };
    const dataStr = JSON.stringify(combinedData, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'raw-data.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  closeView() {
    this.dialogRef.close();
  }

  get showAdditionalDetails(): boolean {
    return (this.data.voucher?.trim().length > 0 || this.data.name?.trim().length > 0);
}

getContentType(): string {
  // Check explicit fields first
  if (this.data.videoUrl) {
    return 'video';
  }
  if (this.data.audioUrl) {
    return 'audio';
  }
  if (this.data.imageUrl) {
    return 'image';
  }
  if (this.data.content) {
    return 'text';
  }

  // If contentUrl exists, determine type from file extension
  if (this.data.contentUrl) {
    const url = this.data.contentUrl.toLowerCase();
    
    if (url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.avi') || url.includes('video')) {
      return 'video';
    }
    if (url.endsWith('.mp3') || url.endsWith('.wav') || url.endsWith('.ogg') || url.includes('audio')) {
      return 'audio';
    }
    if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif') || url.includes('image')) {
      console.log('Image33333', url)
      return 'image';
    }
    if (url.endsWith('.txt') || url.endsWith('.json')) {
      return 'text';
    }

    // Default case if contentUrl is unknown
    return 'unknown';
  }

  return 'unknown';
}







downloadContent() {
  if (this.activeTab === 1) {
    this. contentDownload()
  } else if (this.activeTab === 2) {
    this.downloadRawData();
  } else {
    console.error('Unexpected active tab ID:', this.activeTab);
  }
}

}




// downloadImage() {
//   const a = document.createElement('a');
//   a.href = this.data.imageUrl;
//   a.download = 'downloaded-image';
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
// }

// downloadRawData() {
//   const dataStr = JSON.stringify(this.data.entry, null, 2);
//   const blob = new Blob([dataStr], { type: 'application/json' });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = 'raw-data.json';
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
//   window.URL.revokeObjectURL(url);
// }